type State = {
  code: string
  name: string
  medicaid: string
  minOtc: number | null
  maxOtc: number | null
}

export const states: State[] = [
  {
    code: 'AL',
    name: 'Alabama',
    medicaid: 'Alabama Medicaid',
    minOtc: 100,
    maxOtc: 2200,
  },
  {
    code: 'AK',
    name: 'Alaska',
    medicaid: 'AlaskaCare (Medicaid)',
    minOtc: null,
    maxOtc: null,
  },
  {
    code: 'AZ',
    name: 'Arizona',
    medicaid: 'AHCCCS (Arizona Medicaid)',
    minOtc: 60,
    maxOtc: 3180,
  },
  {
    code: 'AR',
    name: 'Arkansas',
    medicaid: 'ARHOME (Medicaid)',
    minOtc: 80,
    maxOtc: 2160,
  },
  {
    code: 'CA',
    name: 'California',
    medicaid: 'Medi-Cal (Medicaid)',
    minOtc: 60,
    maxOtc: 1920,
  },
  {
    code: 'CO',
    name: 'Colorado',
    medicaid: 'Colorado Medicaid',
    minOtc: 80,
    maxOtc: 2160,
  },
  {
    code: 'CT',
    name: 'Connecticut',
    medicaid: 'Connecticut Medicaid',
    minOtc: 120,
    maxOtc: 1560,
  },
  {
    code: 'DE',
    name: 'Delaware',
    medicaid: 'Delaware Medical Assistance (Medicaid)',
    minOtc: 100,
    maxOtc: 2160,
  },
  {
    code: 'FL',
    name: 'Florida',
    medicaid: 'Florida Medicaid',
    minOtc: 100,
    maxOtc: 3360,
  },
  {
    code: 'GA',
    name: 'Georgia',
    medicaid: 'Georgia Medicaid',
    minOtc: 100,
    maxOtc: 2160,
  },
  {
    code: 'HI',
    name: 'Hawaii',
    medicaid: 'Hawaii Medicaid',
    minOtc: 100,
    maxOtc: 2100,
  },
  {
    code: 'ID',
    name: 'Idaho',
    medicaid: 'Idaho Medicaid',
    minOtc: 100,
    maxOtc: 1460,
  },
  {
    code: 'IL',
    name: 'Illinois',
    medicaid: 'HealthChoice Illinois (Medicaid)',
    minOtc: 60,
    maxOtc: 1500,
  },
  {
    code: 'IN',
    name: 'Indiana',
    medicaid: 'Hoosier Healthwise (Medicaid)',
    minOtc: 60,
    maxOtc: 1500,
  },
  {
    code: 'IA',
    name: 'Iowa',
    medicaid: 'IA Health Link (Medicaid)',
    minOtc: 60,
    maxOtc: 2460,
  },
  {
    code: 'KS',
    name: 'Kansas',
    medicaid: 'KanCare (Medicaid)',
    minOtc: 100,
    maxOtc: 2760,
  },
  {
    code: 'KY',
    name: 'Kentucky',
    medicaid: 'Kentucky Medicaid',
    minOtc: 60,
    maxOtc: 1800,
  },
  {
    code: 'LA',
    name: 'Louisiana',
    medicaid: 'Bayou Health / HealthyLouisiana (Medicaid)',
    minOtc: 100,
    maxOtc: 2760,
  },
  {
    code: 'ME',
    name: 'Maine',
    medicaid: 'MaineCare (Medicaid)',
    minOtc: 100,
    maxOtc: 1100,
  },
  {
    code: 'MD',
    name: 'Maryland',
    medicaid: 'Medical Assistance (Medicaid)',
    minOtc: 100,
    maxOtc: 2120,
  },
  {
    code: 'MA',
    name: 'Massachusetts',
    medicaid: 'MassHealth (Medicaid)',
    minOtc: 120,
    maxOtc: 1500,
  },
  {
    code: 'MI',
    name: 'Michigan',
    medicaid: 'Medical Assistance or MA (Medicaid)',
    minOtc: 60,
    maxOtc: 2160,
  },
  {
    code: 'MN',
    name: 'Minnesota',
    medicaid: 'Medical Assistance (MA) / MinnesotaCare (Medicaid)',
    minOtc: 100,
    maxOtc: 2040,
  },
  {
    code: 'MS',
    name: 'Mississippi',
    medicaid: 'MississippiCAN (Medicaid)',
    minOtc: 40,
    maxOtc: 2160,
  },
  {
    code: 'MO',
    name: 'Missouri',
    medicaid: 'MO HealthNet (Medicaid)',
    minOtc: 100,
    maxOtc: 2460,
  },
  {
    code: 'MT',
    name: 'Montana',
    medicaid: 'Montana Medicaid',
    minOtc: 100,
    maxOtc: 1560,
  },
  {
    code: 'NE',
    name: 'Nebraska',
    medicaid: 'ACCESSNebraska (Medicaid)',
    minOtc: 60,
    maxOtc: 2460,
  },
  {
    code: 'NV',
    name: 'Nevada',
    medicaid: 'Nevada Medicaid',
    minOtc: 40,
    maxOtc: 1600,
  },
  {
    code: 'NH',
    name: 'New Hampshire',
    medicaid: 'New Hampshire Medicaid',
    minOtc: 20,
    maxOtc: 1500,
  },
  {
    code: 'NJ',
    name: 'New Jersey',
    medicaid: 'New Jersey FamilyCare (Medicaid)',
    minOtc: 100,
    maxOtc: 3600,
  },
  {
    code: 'NM',
    name: 'New Mexico',
    medicaid: 'Centennial Care (Medicaid)',
    minOtc: 100,
    maxOtc: 1300,
  },
  {
    code: 'NY',
    name: 'New York',
    medicaid: 'Medicaid Managed Care (Medicaid)',
    minOtc: 20,
    maxOtc: 2400,
  },
  {
    code: 'NC',
    name: 'North Carolina',
    medicaid: 'NC Medicaid',
    minOtc: 60,
    maxOtc: 3660,
  },
  {
    code: 'ND',
    name: 'North Dakota',
    medicaid: 'North Dakota Medicaid',
    minOtc: 100,
    maxOtc: 1100,
  },
  {
    code: 'OH',
    name: 'Ohio',
    medicaid: 'Medicaid',
    minOtc: 60,
    maxOtc: 2760,
  },
  {
    code: 'OK',
    name: 'Oklahoma',
    medicaid: 'SoonerCare (Medicaid)',
    minOtc: 100,
    maxOtc: 2460,
  },
  {
    code: 'OR',
    name: 'Oregon',
    medicaid: 'Oregon Health Plan (Medicaid)',
    minOtc: 80,
    maxOtc: 1800,
  },
  {
    code: 'PA',
    name: 'Pennsylvania',
    medicaid: 'Medical Assistance (MA) (Medicaid)',
    minOtc: 60,
    maxOtc: 2400,
  },
  {
    code: 'RI',
    name: 'Rhode Island',
    medicaid: 'Medical Assistance (Medicaid)',
    minOtc: 100,
    maxOtc: 2160,
  },
  {
    code: 'SC',
    name: 'South Carolina',
    medicaid: 'Healthy Connections (Medicaid)',
    minOtc: 100,
    maxOtc: 2460,
  },
  {
    code: 'SD',
    name: 'South Dakota',
    medicaid: 'South Dakota Medicaid',
    minOtc: 100,
    maxOtc: 1260,
  },
  {
    code: 'TN',
    name: 'Tennessee',
    medicaid: 'TennCare (Medicaid)',
    minOtc: 60,
    maxOtc: 3360,
  },
  {
    code: 'TX',
    name: 'Texas',
    medicaid: 'Medicaid',
    minOtc: 40,
    maxOtc: 2100,
  },
  {
    code: 'UT',
    name: 'Utah',
    medicaid: 'Utah Medicaid',
    minOtc: 60,
    maxOtc: 1640,
  },
  {
    code: 'VT',
    name: 'Vermont',
    medicaid: 'Green Mountain Care (Medicaid)',
    minOtc: 100,
    maxOtc: 1200,
  },
  {
    code: 'VA',
    name: 'Virginia',
    medicaid: 'Virginia Medicaid',
    minOtc: 60,
    maxOtc: 3600,
  },
  {
    code: 'WA',
    name: 'Washington',
    medicaid: 'Apple Health (Medicaid)',
    minOtc: 60,
    maxOtc: 2440,
  },
  {
    code: 'WV',
    name: 'West Virginia',
    medicaid: 'West Virginia Medicaid',
    minOtc: 60,
    maxOtc: 1500,
  },
  {
    code: 'WI',
    name: 'Wisconsin',
    medicaid: 'ForwardHealth / BadgerCare (Medicaid)',
    minOtc: 60,
    maxOtc: 2460,
  },
  {
    code: 'WY',
    name: 'Wyoming',
    medicaid: 'EqualityCare (Medicaid)',
    minOtc: 100,
    maxOtc: 1260,
  },
]
